<template>

    <Row>
        <i-col span="8">
            <div style="height:100%;" >
                <div id="container"></div>
            </div>
        </i-col>
    </Row>
    <!--连线组件-->
    <!--<div ref="lineSvg" class="link-line">-->
        <!--<svg class="svg">-->
            <!--<path d="M 0 450 C 50 450, 700 100, 800, 300" stroke="#F5003C" stroke-width="8" fill="RGB(251,251,251)" />-->
        <!--</svg>-->
    <!--</div>-->
</template>

<script>
    import { Chart } from '@antv/g2';
    export default {
        data () {
            return {
                timer: null,
                // svgWidth: '',
                // svgHeight: '',
                // radio: 36,
                // fontSize: 36,
                // textList: [],
                // lastNode: {},
                // viewX: 0,
                // viewY: 0,
                // viewW: 100 + '%',
                // viewH: 100 + '%',
                // circleCoord: [
                //     {
                //         cx: 50,
                //         cy: 50,
                //         color: "#" + Math.random().toString(16).slice(2, 8)
                //     },
                // ],
                // cx: 50,
                // cy: 50,
            }
        },
        created() {
            this.draw()
        },
        mounted() {
            this.draw()
        },
        methods: {
            draw: function () {

                const data = [
                    { month: '0', temperature: 1000 },
                    { month: '1000', temperature: 1100 },
                    { month: '2000', temperature: 1200 },
                    { month: '3000', temperature: 1500 },
                    { month: '4000', temperature: 1200 },
                    { month: '5000', temperature: 1100 },
                    { month: '', temperature: 1000 },
                ];

                const chart = new Chart({
                    container: 'container',
                    autoFit: true,
                    height: 500,
                });

                chart.data(data);
                chart.scale({
                    month: {
                        range: [0, 1],
                    },
                    temperature: {
                        ticks: [1000, 2000, 3000],
                        nice: true,
                    },
                });
                // 曲线折线图
                chart.tooltip({
                    showCrosshairs: false, // 是否显示辅助线 鼠标滑过的线
                    shared: true,
                });

                chart.axis('temperature', {
                    grid: null, // 关闭网格
                    label: {
                        formatter: (val) => {
                            return val;
                        },
                    },
                });
                chart.animate(false) // 关闭动画
                chart
                    .line()
                    .position('month*temperature')
                    .shape('smooth');
                // 曲线折线图 上的点
                // chart
                //     .point()
                //     .position('month*temperature')
                //     .shape('circle'); // 点的形状
                // 指定位置添加文本展示
                // chart.annotation().text({
                //     content: '指定位置添加文本展示',
                //     position: ['10%', '20%']
                // });
                // chart.annotation().text({
                //     content: '指定位置添加文本展示',
                //     position: ['50%', '20%']
                // });
                // chart.annotation().text({
                //     content: '指定位置添加文本展示',
                //     position: ['70%', '20%']
                // });
                // 根据x,y轴 定点提示
                chart.annotation().dataMarker({
                    top: true,
                    position: ['1000', 1100],
                    line: {
                      length: 30,
                    },
                });
                // 辅助 添加竖线
                chart.annotation().line({
                    top: true,
                    start: ['2000', 'min'],
                    end: ['2000', 'max'],
                })
                chart.annotation().line({
                    top: true,
                    start: ['4000', 'min'],
                    end: ['4000', 'max'],
                })
                // chart.annotation().region({
                //     start: 0,
                //     end: 0,
                //     offsetX: 0,
                //     offsetY: 100,
                //     style: {
                //         fill: 'red',
                //         path: [
                //             ['M', 100, 100],
                //             ['L', 200, 200],
                //         ]
                //     }
                // });
                // 区域上色 改变线条颜色
                chart.annotation().regionFilter({
                    top: true,
                    start: ['min', 'min'],
                    end: ['max', 'max'],
                    color: '#ff4d4f'
                });
                // 辅助区间
                chart.annotation().region({
                    start: ['0', 'min'],
                    end: ['2000', 'max'],
                    style: {
                        // https://g2.antv.vision/zh/docs/manual/shape-attrs/#%E7%BA%BF%E6%80%A7%E6%B8%90%E5%8F%98
                        fill: 'l(90) 0:#1E90FF 1:#DC143C',
                    }
                });
                chart.annotation().region({
                    start: ['2000', 'min'],
                    end: ['4000', 'max'],
                    style: {
                        // https://g2.antv.vision/zh/docs/manual/shape-attrs/#%E7%BA%BF%E6%80%A7%E6%B8%90%E5%8F%98
                        fill: 'l(90) 0:#0000FF 1:#DC143C'
                    }
                });
                chart.annotation().region({
                    start: ['4000', 'min'],
                    end: ['max', 'max'],
                    style: {
                        // https://g2.antv.vision/zh/docs/manual/shape-attrs/#%E7%BA%BF%E6%80%A7%E6%B8%90%E5%8F%98
                        fill: 'l(90) 0:#1E90FF 1:#DC143C'
                    }
                });
                chart.render();

            }
        }
    }
</script>

<style scoped lang="scss">
    .link-line {
        height: 500px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #0E86E7;
    }
    .svg {
        height: 100%;
        width: 100%;
        /*fill: #9A9A9A;*/
    }
    .dot-normal {
        stroke:#0E86E7;
        stroke-width: 2;
        fill:#CFEAFF;
    }
    .dot-linked {
        stroke-width: 2;
        fill:#0E86E7;
    }
</style>
