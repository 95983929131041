<template>
    <div class="layout">
        <Layout>
            <Header :style="{position: 'fixed', width: '100%', background: '#2685F6'}">
                <Menu mode="horizontal" theme="primary" active-name="1">
                    <div class="layout-logo"><img src="../../assets/logo.png"> 脑影像分析系统</div>
                    <div class="layout-nav">
                        <MenuItem name="1">
                            <Icon type="ios-analytics"></Icon>
                            患者管理
                        </MenuItem>
                        <MenuItem name="2">
                            <Icon type="ios-keypad"></Icon>
                            报告查询
                        </MenuItem>
                        <Submenu name="5">
                            <template slot="title">
                                <Icon type="ios-stats" />
                                系统管理
                            </template>
                            <MenuGroup title="使用">
                                <MenuItem name="3-1">新增和启动</MenuItem>
                                <MenuItem name="3-2">活跃分析</MenuItem>
                                <MenuItem name="3-3">时段分析</MenuItem>
                            </MenuGroup>
                            <MenuGroup title="留存">
                                <MenuItem name="3-4">用户留存</MenuItem>
                                <MenuItem name="3-5">流失用户</MenuItem>
                            </MenuGroup>
                        </Submenu>
                    </div>
                </Menu>
            </Header>

            <Layout style="height:calc(100vh - 64px); margin-top: 64px">
                <Layout :style="{margin: '24px 0', padding: '0 24px 24px'}">
                                        <Content :style="{padding: '24px', minHeight: '280px', background: '#fff'}">
                                            <Content></Content>
                                        </Content>
                </Layout>
            </Layout>
        </Layout>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="less" scoped>
    .layout{
        /*border: 1px solid #d7dde4;*/
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout-logo{
        /*width: 100px;*/
        /*height: 30px;*/
        /*background: #5b6270;*/
        /*border-radius: 3px;*/
        /*float: left;*/
        /*position: relative;*/
        /*top: 15px;*/
        /*left: 20px;*/

        color: #fff;
        line-height: 30px;
        font-size: 25px;
        height: 30px;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px;
        font-weight: bold;
    }
    .layout-nav{
        width: 720px;
        margin: 0 auto;
        /*margin-right: 20px;*/
        margin-left: 300px;
    }
    .layout-footer-center{
        text-align: center;
    }
    .ivu-menu-horizontal {
        height: auto;
        line-height: 62px;
    }
    .ivu-menu-item, .ivu-menu-submenu {
        font-size: 18px;
    }
    .ivu-menu-item-active {
        border-bottom: #f5f7f9 2px solid;
    }
</style>
