<template>

    <div style="height:100px; margin: 100px 100px">
        <input type="button" value="顺序执行" @click="toTest">
        <br><br><br><br>
        <input type="button" value="并发执行后合并数据" @click="toTest2">
        <br><br><br><br>
        <input type="button" value="Promise顺序执行" @click="toTest3">
        <br><br><br><br>
        <input type="button" value="Promise处理网络请求，顺序执行" @click="toTest4">

        <br><br><br><br>
        <input type="button" value="加载本地json" @click="toTest5">
    </div>

</template>

<script>
    // import axios from 'axios'
    export default {
        data () {
            return {
                timer: null,
            }
        },
        methods: {
            /**
             * 多个接口请求顺序执行测试方法
             * - 后面的方法依赖前面的返回值做为请求参数的情况
             * - 如果不相互依赖一般情况下尽量并发执行
             * async 标记
             * @returns {Promise<void>}
             */
            toTest: async function () {
                console.log('开始执行');
                /**
                 * 执行第1个方法，封装接口
                 * 方法返回数据后 then处理数据
                 */
                await this.first()
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            console.log(' first 执行完成');
                        } else {
                            console.log(res.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });
                /**
                 * 执行第2个方法，封装接口
                 * 方法内部全部处理完数据
                 */
                await this.second();
                /**
                 * 执行第3个方法，直接请求接口
                 */
                console.log('===========================方法3 ===========================');
                let data = {
                    userId: 8888,
                    userName: 'DerekFan'
                };
                await this.apis.postJson('system/hospital/getList3', data)
                    .then((res) => {
                        if (res.code === 1) {
                            console.log('方法3 已接收到数据');
                            this.sleep(5000);
                            console.log('方法3 数据处理完成');
                        } else {
                            console.log('3发生错误' + res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                console.log("全部处理结束")
            },
            first: function () {
                console.log('===========================方法1 ===========================');
                console.log('准备加载第1批次数据。。。。。。。。。');
                this.sleep(5000);
                let data = {
                    userId: 8888,
                    userName: 'DerekFan'
                };
                return this.apis.get('system/hospital/getList?s=1', data)
            },
            second: async function () {
                console.log('===========================方法2 ===========================');
                console.log('准备加载第2批次数据。。。。。。。。。');
                this.sleep(5000);
                let data = {
                    userId: 8888,
                    userName: 'DerekFan'
                };
                await this.apis.postForm('system/hospital/getList2?s=1121', data)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            console.log('second执行完成');
                        } else {
                            console.log('2发生错误' + res.msg);
                        }
                    })
                    .catch(err => console.log(err));
                console.log('===========================方法2 END ===========================');
            },
            sleep: function (time) {
                console.log('等待' + time + 'ms；数据处理中......');
                let startTime = new Date().getTime() + parseInt(time, 10);
                while (new Date().getTime() < startTime) {
                    if (new Date().getTime() >= startTime) {
                        console.log('...............处理结束');
                    }
                }
                console.log('...............处理结束');
            },
            toTest2: function () {
                // 方法1 使用axios
                // import axios from 'axios'
                // axios.all([
                //     this.first(),
                //     this.apis.get('system/hospital/getList2?s=1')
                // ]).then(axios.spread(
                //     (res1, res2) => {
                //         console.log("全部请求完毕，开始合并数据");
                //         console.log(res1);
                //         console.log(res2);
                //     }
                // )).catch(err => {
                //     console.log(err);
                // });

                // 方法2
                Promise.all([
                    this.first(),
                    this.apis.get('system/hospital/getList2?s=1')
                ]).then(([res1, res2]) => {
                    console.log("全部请求完毕，开始合并数据");
                    console.log(res1);
                    console.log(res2);
                })
            },
            /**
             * 等第一个执行完成后，会把结果传递到then，执行第二个
             */
            toTest4: function () {
                new Promise((resolve, reject) => {
                    console.log("第一个方法")
                    let data = {
                        userId: 8888,
                        userName: 'DerekFan'
                    };
                    this.apis.get('system/hospital/getList?s=1', data)
                        .then((res) => {
                            this.sleep(5000);
                            resolve({
                                res: res
                            })
                        })
                        .catch(err => console.log(err));
                }).then((result) => {
                    console.log(2, result)
                })
            },
            toTest3: function () {
                new Promise((resolve, reject) => {
                    console.log(1)
                    resolve({
                        name: "第1个传递的值"
                    });
                }).then((result) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            console.log(2, result)
                            resolve({
                                name: "第2个传递的值"
                            })
                        }, 1000)
                    })
                }).then((result) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            console.log(3, result)
                            resolve({
                                name: "第3个传递的值"
                            })
                        }, 1000)
                    })
                }).then((result) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            console.log(4, result)
                            resolve({
                                name: "第4个传递的值"
                            })
                        }, 1000)
                    })
                }).then((result) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            console.log(5, result)
                        }, 1000)
                    })
                })
            },
            toTest5: function () {
                new Promise((resolve, reject) => {
                    console.log("第一个方法")
                    let data = {
                        userId: 8888,
                        userName: 'DerekFan'
                    };
                    this.apis.get('system/hospital/getList?s=1', data)
                        .then((res) => {
                            this.sleep(5000);
                            resolve({
                                res: res
                            })
                        })
                        .catch(err => console.log(err));
                }).then((result) => {
                    console.log(2, result)
                })
            },
        }
    }
</script>

<style scoped>

</style>
